<app-search-top-nav></app-search-top-nav>
<section id="business-search" class="search">
  <div class="container">
    <div class="logo-div d-flex justify-content-center">
      <img class="logo" src="../../../../../assets/Images/new/HUT-icon.v3-business-RO.png" />
    </div>
    <form role="form" [formGroup]="businessForm">
      <div class="form-group row form-top-margin">
        <div class="col-md-9 offset-md-2 form-data-background form-borderdesign  shadow-lg p-3">
          <div class="row padding-form-design">
            <div class="col-md-12 z-index-formdata">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                    <b>Reference:</b>
                  </div>
                  <div class="col-md-8 pt-3">
                    <div class="form-group">
                      <input type="text" placeholder="" class="form-control" formControlName="reference" autocomplete="off" />
                      <p class="text-danger" *ngIf="businessForm.get('reference').errors?.required">
                        <strong><small>Reference is required</small></strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 z-index-formdata">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                    <b>Company Number:</b>
                  </div>
                  <div class="col-md-8 pt-3">
                    <div class="form-group">
                      <input type="text" placeholder="" class="form-control" formControlName="companyNumber" autocomplete="off" (input)="onCompanyNumberChange()" />
                      <p class="text-danger" *ngIf="businessForm.get('companyNumber').errors?.required">
                        <strong><small>{{messagingService.BusinessValidationMessage}}</small></strong>
                      </p>
                      <p class="text-danger" *ngIf="businessForm.get('companyNumber').errors?.pattern">
                        <strong><small>{{messagingService.CompanyNumberNotValidMessage}}</small></strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 z-index-formdata">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                    <b>Business Name:</b>
                  </div>
                  <div class="col-md-8 pt-3">
                    <div class="form-group">
                      <input type="text" placeholder="" class="form-control" formControlName="businessName" autocomplete="off" (input)="onCompanyNameChange()" />
                      <p class="text-danger" *ngIf="businessForm.get('businessName').errors?.required">
                        <strong><small>{{messagingService.BusinessValidationMessage}}</small></strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 z-index-formdata">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                    <b>Business Postcode:</b>
                  </div>
                  <div class="col-md-8 pt-3">
                    <div class="form-group mb-0">
                      <input type="text" placeholder="" class="form-control" formControlName="businessPostcode" autocomplete="off" (input)="onCompanyPostcodeChange()" />
                      <p class="non-limited-label">Use for Non-limited business searches only</p>
                      <p class="text-danger" *ngIf="businessForm.get('businessPostcode').errors?.required">
                        <strong><small>{{messagingService.BusinessValidationMessage}}</small></strong>
                      </p>
                      <p class="text-danger" *ngIf="businessForm.get('businessPostcode').errors?.pattern">
                        <strong><small>{{messagingService.CompanyPostcodeNotValid}}</small></strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 z-index-formdata">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                    <b>VAT Number:</b>
                  </div>
                  <div class="col-md-8 pt-3">
                    <div class="form-group">
                      <input type="text" placeholder="" class="form-control" formControlName="vatNumber" autocomplete="off" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 z-index-formdata">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                    <b>Email:</b>
                  </div>
                  <div class="col-md-8 pt-3">
                    <div class="form-group">
                      <input type="email" placeholder="" class="form-control" formControlName="email" autocomplete="off" (input)="onEmailChange()" />
                      <p class="text-danger" *ngIf="businessForm.get('email').errors?.pattern">
                        <strong><small>{{messagingService.EmailNotValidMessage}}</small></strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 z-index-formdata">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                    <b>Mobile:</b>
                  </div>
                  <div class="col-md-8 pt-3">
                    <div class="form-group">
                      <input type="text" placeholder="" class="form-control" formControlName="mobile" autocomplete="off" (input)="onMobileChange()" />
                      <p class="text-danger" *ngIf="businessForm.get('mobile').errors?.pattern">
                        <strong><small>Please enter valid mobile numbers</small></strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 z-index-formdata">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                    <b>Proprietor Name:</b>
                  </div>
                  <div class="col-md-8 pt-3">
                    <div class="form-group mb-0">
                      <input type="text" placeholder="" class="form-control" formControlName="name" autocomplete="off" />
                      <p class="non-limited-label">Use for Non-limited business searches only</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 z-index-formdata">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                    <b>Proprietor Post Code:</b>
                  </div>
                  <div class="col-md-8 pt-3">
                    <div class="form-group mb-0">
                      <input type="text" placeholder="" class="form-control" formControlName="postcode" autocomplete="off" />
                      <p class="non-limited-label">Use for Non-limited business searches only</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 z-index-formdata">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                    <b>Proprietor DOB:</b>
                  </div>
                  <div class="col-md-8 pt-3">
                    <div class="form-group mb-0">
                      <input type="date" placeholder="" class="form-control datepicker" formControlName="dob" autocomplete="off" />
                      <p class="non-limited-label">Use for Non-limited business searches only</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 padding-form-design pb-2">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-12 offset-md-3">
                    <div class="form-group">
                      <button type="submit" class="btn colorRed mx-2" (click)="businessSearch()">Search <i class="fa fa-search" aria-hidden="true"></i></button>
                      <button type="button" class="btn colorRed btn-pdf mx-2"
                              *ngIf="saveAsPDFButtonVisibility"
                              (click)="generatePDF()"
                              [disabled]="emailValidationResultModel?.isLoading || emailageResultModel?.isLoading || mobileResultModel?.isLoading ||
                              prospectResultModel?.isProspectHitLoading || prospectResultModel?.isProspectProfileLoading || opensourceResult?.isLoading || vatResult?.isLoading || businessResult?.isLoading ||
                              addressResultModel?.isLoading || counterFraudResultModel?.isLoading || companiesHouseResultModel?.isLoading">
                        Save as PDF
                      </button>
                      <button class="btn colorRed reset-btn mx-2"
                              *ngIf="clearSearchButtonVisibility"
                              (click)="businessClearSearch()"
                              [disabled]="emailValidationResultModel?.isLoading || emailageResultModel?.isLoading || mobileResultModel?.isLoading ||
                              prospectResultModel?.isProspectHitLoading || prospectResultModel?.isProspectProfileLoading || opensourceResult?.isLoading || vatResult?.isLoading || businessResult?.isLoading ||
                              addressResultModel?.isLoading || counterFraudResultModel?.isLoading || companiesHouseResultModel?.isLoading">
                        Clear Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div>
      <div class="container" *ngIf="showResultTab">
        <div class="row mb-3" id="new-dashboard">
          <div class="offset-md-2 col-md-3 card">
            <app-dashboard-one-business [business]="businessResult" [requestData]="requestData"></app-dashboard-one-business>
          </div>
          <div class="col-md-3 card">
            <app-dashboard-two-business [business]="businessResult"
                                        [requestData]="requestData"
                                        [companiesHouse]="companiesHouseResultModel"
                                        [prospect]="prospectResultModel"></app-dashboard-two-business>
          </div>
          <div class="col-md-3 card">
            <app-dashboard-three-business [emailage]="emailageResultModel" [mobile]="mobileResultModel"></app-dashboard-three-business>
          </div>
        </div>
      </div>

      <div class="container" *ngIf="showResultTab">
        <div class="mb-5">
          <div class="background-white result-display-padding form-borderdesign result-view-align" style="border: 1px solid rgba(0,0,0,.125);">
            <ul class="nav nav-tabs" id="searchResultTab" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" id="business-search-tab" data-toggle="tab" href="#businessSearch" role="tab"
                   aria-controls="profile" aria-selected="false"><b>Business</b></a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="landregistry-search-tab" data-toggle="tab" href="#landregistrySearch" role="tab"
                   aria-controls="profile" aria-selected="false"><b>Land Registry</b></a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="opensource-search-tab" data-toggle="tab" href="#openSourceSearch" role="tab"
                   aria-controls="profile" aria-selected="false"><b>Open Source</b></a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="vat-search-tab" data-toggle="tab" href="#vatSearch" role="tab"
                   aria-controls="profile" aria-selected="false"><b>VAT</b></a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="email-search-tab" data-toggle="tab" href="#emailSearch" role="tab"
                   aria-controls="profile" aria-selected="false"><b>Email</b></a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="mobile-search-tab" data-toggle="tab" href="#mobileSearch" role="tab"
                   aria-controls="profile" aria-selected="false"><b>Mobile</b></a>
              </li>
              <li class="nav-item" *ngIf="prospectResultModel.isProspectHitAccess">
                <a class="nav-link" id="prospect-search-tab" data-toggle="tab" href="#prospectSearch" role="tab"
                   aria-controls="profile" aria-selected="false"><b>Prospect</b></a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="proprietor-search-tab" data-toggle="tab" href="#proprietorSearch" role="tab"
                   aria-controls="profile" aria-selected="false"><b>Proprietor</b></a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="socialmedia-search-tab" data-toggle="tab" href="#socialmediaSearch" role="tab"
                   aria-controls="profile" aria-selected="false"><b>Social Media</b></a>
              </li>
            </ul>

            <div class="tab-content" id="searchResultTabContent">

              <!-- Email Result -->
              <div class="tab-pane fade show tab-height" id="emailSearch" role="tabpanel" aria-labelledby="email-search-tab">
                <app-emailvalidation-result-email [emailValidation]="emailValidationResultModel"></app-emailvalidation-result-email>
                <br />
                <app-emailage-result-email [emailage]="emailageResultModel"></app-emailage-result-email>
              </div>

              <!-- Mobile Result -->
              <div class="tab-pane fade show tab-height" id="mobileSearch" role="tabpanel" aria-labelledby="mobile-search-tab">
                <app-mobile-result-mobile [mobile]="mobileResultModel"></app-mobile-result-mobile>
              </div>

              <!-- Prospect Result -->
              <div class="tab-pane fade show tab-height" id="prospectSearch" role="tabpanel" aria-labelledby="prospect-search-tab">
                <app-prospect-result-personal [prospect]="prospectResultModel" (prospectProfileRequestEventEmitter)="prospectProfileRequest()"></app-prospect-result-personal>
              </div>

              <!-- Open Source Result -->
              <div class="tab-pane fade show tab-height" id="openSourceSearch" role="tabpanel" aria-labelledby="opensource-search-tab">
                <app-opensource-result-business [opensource]="opensourceResult"></app-opensource-result-business>
              </div>

              <!-- VAT Result -->
              <div class="tab-pane fade show tab-height" id="vatSearch" role="tabpanel" aria-labelledby="vat-search-tab">
                <app-vat-result-business [vatResult]="vatResult"></app-vat-result-business>
              </div>

              <!-- Business Result -->
              <div class="tab-pane fade show tab-height active" id="businessSearch" role="tabpanel" aria-labelledby="business-search-tab">
                <app-business-result-business [business]="businessResult"></app-business-result-business>
              </div>

              <!-- Proprietor -->
              <div class="tab-pane fade show tab-height" id="proprietorSearch" role="tabpanel" aria-labelledby="proprietor-search-tab">
                <div *ngIf="(businessForm.get('name').value && (businessForm.get('dob').value || businessForm.get('postcode').value)); else proprietorNoResult">
                  <div class="row">
                    <div class="col-md-4 offset-md-2">
                      <app-counterfraud-new-result-personal [counterFraud]="counterFraudResultModel" [financial]="addressResultModel" [mobile]="mobileResultModel"></app-counterfraud-new-result-personal>
                    </div>
                    <div class="col-md-4">
                      <app-financial-new-result-personal [financial]="addressResultModel"></app-financial-new-result-personal>
                    </div>
                  </div>
                  <app-address-result-personal [address]="addressResultModel"></app-address-result-personal>
                </div>
                <ng-template #proprietorNoResult>
                  <div class="col-md-12"><i class="color-grey float-right">{{messagingService.ProprietorDetailsRequired}}</i></div>
                </ng-template>
              </div>

              <!-- Land Registry Result -->
              <div class="tab-pane fade show tab-height" id="landregistrySearch" role="tabpanel" aria-labelledby="landregistry-search-tab">
                <app-landregistry-result-business [landRegistry]="landRegistryResultModel"></app-landregistry-result-business>
              </div>

              <!-- Social Media Result -->
            <div class="tab-pane fade show tab-height" id="socialmediaSearch" role="tabpanel" aria-labelledby="socialmedia-search-tab">
              <app-socialmedia-result-business [socialMedia]="socialMediaResultModel"></app-socialmedia-result-business>
            </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-1"></div>
  </div>
</section>
<p-confirmDialog key="ProspectProfileRequest" [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>

<div style="position: absolute; top: 0; right: 120%;">
  <kendo-pdf-export #pdf>
    <app-export-pdf-business [requestData]="requestDataModel"
                             [business]="businessResult"
                             [companiesHouse]="companiesHouseResultModel"
                             [prospect]="prospectResultModel"
                             [emailage]="emailageResultModel"
                             [emailValidation]="emailValidationResultModel"
                             [mobile]="mobileResultModel"
                             [opensource]="opensourceResult"
                             [vatResult]="vatResult"
                             [counterFraud]="counterFraudResultModel"
                             [address]="addressResultModel"
                             [landRegistry]="landRegistryResultModel"></app-export-pdf-business>
  </kendo-pdf-export>
</div>
